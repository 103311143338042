import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Footer } from './Footer';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

const Beg = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);

  // Fetch cards data from API
  useEffect(() => {
    fetch('https://intowordsgc.com/api/cards/category/beginning')
      .then((response) => response.json())
      .then((data) => setCards(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleLinkClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className=' ' style={{ background: "#000" }} >
        <Helmet>
          <title>Into Words - Beginning</title>
        </Helmet>
        <Header />
        <div className='beg'>
          <br />
          <h2>SHOP BY CATEGORY</h2>
          <h3>BEGINNING</h3>
        </div>
        <div className='container'>
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            style={{
              '--swiper-navigation-color': '#fff',
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            {cards.map((card, index) => (
              <SwiperSlide key={index}>
                <div className='cards'>
                  {/* Ensure the image is properly displayed */}
                  <img 
                    src={`data:image/png;base64,${card.image}`} 
                    className="img2" 
                    alt={card.card_name} 
                  />
                  <div className="d-flex align-items-center" style={{ position: "absolute", top: "90%" }}>
                    <h5 className="text-light title text-start">{card.card_name}</h5>
                  </div>
                  <div className="info">
                    <Link to={`/card/${card.card_name}`} onClick={() => handleLinkClick(`/card/${card.card_name}`)} state={{ card }} className="button">View Now</Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Beg;
