import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header/Header';
import { Footer } from './components/Footer';

const stripePromise = loadStripe('pk_live_51PLtOnRuBltPie1wpZ1aJgrCRtbd5Te6glYw1miXuuJGWZz9dgjFkKtE1ta09K194S2lq8uElYDTwhDdOTxp2OZB00y6BEaYiD'); // Replace with your Stripe publishable key.

const PaymentPage = () => {

  const location = useLocation();
  const { card } = location.state || {};

  if (!card) {
    return <div>No card information provided.</div>;
  }

  return (
    <>
      <div className='' style={{ backgroundColor: "#000", height: "auto" }}
      >
        <Header />
        <div className="payment-page">

          <Helmet>
            <title>Into Words - Payment</title>
          </Helmet>
          <div className="product-info">

            <img src={`data:image/png;base64,${card.image}`} className="h-75 w-50 mx-auto d-flex" alt={card.card_name} />
            <h2 className='text-center'>{card.card_name}</h2>
            <p className='text-center'>{card.para}</p>
            <h3 className='text-center'>{`${card.price} x ${card.quantity} = $${(parseFloat(card.price.slice(1)) * card.quantity).toFixed(2)}`}</h3>
          </div>
          <div className="payment-form">
            <h2>Pay with Card</h2>
            <Elements stripe={stripePromise}>
              <CheckoutForm card={card} />
            </Elements>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const CheckoutForm = ({ card }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({

    senderName: '',
    senderEmail: '',
    receiverName: '',
    receiverEmail: '',
    phoneNumber: '',
  });

  const [showCardDetails, setShowCardDetails] = useState(false); // New state
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCheckout = () => {
    console.log('Checkout button clicked');
    const validationErrors = {};

    // Validate only first-step fields
    const requiredFields = ['senderName', 'senderEmail', 'receiverName', 'receiverEmail', 'phoneNumber'];


    requiredFields.forEach((field) => {
      if (!formData[field] || (typeof formData[field] === 'string' && !formData[field].trim())) {
        validationErrors[field] = `${field.replace(/([A-Z])/g, ' $1')} is required`;
      }
    });

    if (!/^\S+@\S+\.\S+$/.test(formData.senderEmail)) {
      validationErrors.senderEmail = 'Invalid email format';
    }



    if (Object.keys(validationErrors).length > 0) {
      console.log('Validation Errors:', validationErrors);
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    setShowCardDetails(true); // Show card details section
  };

  const handlePhoneInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (!value.startsWith('1')) {
      value = '1' + value; // Ensure it starts with 1
    }
    setFormData({ ...formData, phoneNumber: `+${value}` });

    if (value.length < 11) {
      setErrors({ ...errors, phoneNumber: 'Invalid phone number' });
    } else {
      const newErrors = { ...errors };
      delete newErrors.phoneNumber;
      setErrors(newErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim() === '') {
      setErrors({ ...errors, [name]: 'This field is required' });
    } else {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      toast.error('Stripe is not loaded yet.');
      return;
    }

    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (!value.toString().trim()) {
        newErrors[key] = `${key.replace(/_/g, ' ')} is required`;
      }
    });

    if (!card || !card.id) {
      newErrors.card_id = 'Card ID is required';
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    setIsSubmitting(true);

    try {
      const cardElement = elements.getElement(CardElement);

      const { token, error } = await stripe.createToken(cardElement, {
        name: formData.cardholderName,
      });

      if (error) {
        console.error('Stripe Error:', error.message);
        setIsSubmitting(false);
        setErrors({ cardholderName: error.message });
        toast.error(error.message);
        return;
      }

      console.log('Stripe Token:', token.id);

      const payload = {

        sender_name: formData.senderName,
        sender_email: formData.senderEmail,
        receiver_name: formData.receiverName,
        receiver_email: formData.receiverEmail,
        receiver_phone_number: formData.phoneNumber,
        card_id: card.id,
        price: parseFloat(card.price.slice(1)) * card.quantity,
        stripeToken: token.id,
      };

      console.log('Payload to API:', payload);

      const response = await axios.post(
        'https://intowordsgc.com/api/digital-card/order',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Extract order number from backend response
      const order_id = response.data.order_id;

      toast.success('Payment successful!');

      // Navigate to ThankYou page and pass orderNumber as state
      navigate('/thank-you', { state: { order_id } });
      toast.success('Payment successful!');
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      toast.error(`Error: ${error.response?.data?.message || error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <form onSubmit={handleSubmit}>
        {!showCardDetails ? (
          <>
            <div className="row">

              <div className="form-group col-lg-6 col-sm-12">
                <label className="form-label">Sender Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.senderName ? 'is-invalid' : ''}`}
                  name="senderName"
                  value={formData.senderName}
                  onChange={handleInputChange}
                  required
                />
                {errors.senderName && <div className="invalid-feedback">{errors.senderName}</div>}
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label className="form-label">Sender Email Address</label>
                <input
                  type="email"
                  className={`form-control ${errors.senderEmail ? 'is-invalid' : ''}`}
                  name="senderEmail"
                  value={formData.senderEmail}
                  onChange={handleInputChange}
                  required
                />
                {errors.senderEmail && <div className="invalid-feedback">{errors.senderEmail}</div>}
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label className="form-label">Receiver Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.receiverName ? 'is-invalid' : ''}`}
                  name="receiverName"
                  value={formData.receiverName}
                  onChange={handleInputChange}
                  required
                />
                {errors.receiverName && <div className="invalid-feedback">{errors.receiverName}</div>}
              </div>
              <div className="form-group col-lg-6 col-sm-12">
                <label className="form-label">Receiver Email Address</label>
                <input
                  type="email"
                  className={`form-control ${errors.receiverEmail ? 'is-invalid' : ''}`}
                  name="receiverEmail"
                  value={formData.receiverEmail}
                  onChange={handleInputChange}
                  required
                />
                {errors.receiverEmail && <div className="invalid-feedback">{errors.receiverEmail}</div>}
              </div>
              <div className="form-group col-lg-12 col-sm-12">
                <label className="form-label">Receiver Phone Number</label>
                <input
                  type="tel"
                  className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handlePhoneInputChange}
                  required
                />
                {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
              </div>
            </div>
            <button type="button" className="btn btn-primary mt-3" onClick={handleCheckout}>
              Checkout
            </button>
          </>
        ) : (
          <>
            <div className="form-group">
              <label>Cardholder Name</label>
              <input
                type="text"
                name="cardholderName"
                className={`form-control ${errors.cardholderName ? 'is-invalid' : ''}`}
                value={formData.cardholderName}
                onChange={handleInputChange}
                required
              />
              {errors.cardholderName && <div className="invalid-feedback">{errors.cardholderName}</div>}
            </div>
            <div className="form-group">
              <label>Card Details</label>
              <CardElement className="form-control" />
            </div>
            <button type="submit" className="btn btn-primary" disabled={isSubmitting || !stripe}>
              {isSubmitting ? 'Processing...' : 'Pay Now'}
            </button>
            <ToastContainer />

          </>
        )}

      </form>

    </>
  );
};


export default PaymentPage;