import { useLocation, Link } from "react-router-dom";
import Header from "./Header/Header";
import { Footer } from "./Footer";

const ThankYou = () => {
  const location = useLocation();
  const orderNumber = location.state?.order_id || "N/A"; // Get order number from navigation state

  return (
    <div className='bg-dark' style={{ backgroundColor: "#111111", height: "auto" }}>
      <Header /><br />
      <h2 className="text-center text-light">Payment Successful!</h2> <br />
      <p className="text-center text-light">Thank you for your payment.</p>
      <p className="text-center text-light"><strong>Please check your email for order ID.</strong></p>
      <Link to="/" style={{ listStyle: "none", textDecoration: "none" }}>
        <button className="button mx-auto d-flex">Go to Home</button>
      </Link>
      <Footer />
    </div>
  );
};

export default ThankYou;
