import { Footer } from "./Footer";
import Header from "./Header/Header";

export default function PrivacyPolicy() {
    return (
        <div className='bg-dark text-light' style={{ backgroundColor: "#111111", height: "auto" }}>
            <Header />
            <div className="container mt-5">
                <h2 className="fw-bold">Privacy Policy</h2>
                <p><strong>Last Updated:</strong> Feb 5th, 2025</p>

                <p>Into Words Greeting Cards respects your privacy and is committed to protecting your personal information.</p>

                <h4 className="fw-bold">1. Information We Collect</h4>
                <ul>
                    <li><strong>Personal Details:</strong> Name, email address, phone number.</li>
                    <li><strong>Billing & Shipping Information:</strong> Address, payment details (processed securely through Stripe).</li>
                    <li><strong>Account Details:</strong> If you create an account, we collect login credentials.</li>
                </ul>

                <h4 className="fw-bold">2. How We Use Your Information</h4>
                <p>✔ Process orders and payments.</p>
                <p>✔ Deliver digital and physical products.</p>
                <p>✔ Provide customer support.</p>
                <p>✔ Improve website functionality and user experience.</p>

                <h4 className="fw-bold">3. How We Share Your Information</h4>
                <p>✔ Payment Processors (Stripe) for secure transactions.</p>
                <p>✔ Shipping Providers to deliver physical greeting cards.</p>

                <h4 className="fw-bold">4. Data Security</h4>
                <p>We take data security seriously and implement:</p>
                <ul>
                    <li>SSL encryption for secure transactions.</li>
                    <li>Strict access controls to protect customer data.</li>
                    <li>Regular security monitoring to prevent breaches.</li>
                </ul>
                <p>While we take precautions, no online platform is 100% secure. Please protect your account credentials.</p>

                <h4 className="fw-bold">5. Cookies & Tracking Technologies</h4>
                <p>We use cookies to:</p>
                <p>✔ Remember your preferences.</p>
                <p>✔ Improve website performance.</p>
                <p>✔ Provide relevant advertisements.</p>
                <p>You can manage cookie settings in your browser.</p>

                <h4 className="fw-bold">6. Your Rights & Choices</h4>
                <p>✅ Access and update your personal data.</p>
                <p>✅ Request deletion of your account.</p>
                <p>✅ Opt out of marketing emails (unsubscribe link in emails).</p>
                <h4 className="fw-bold">7. Third-Party Links</h4>
                <p>Our website may contain links to third-party sites. We are not responsible for their privacy practices. Please review their policies before sharing your data.</p>
                <h4 className="fw-bold">8. Changes to This Privacy Policy</h4>
                <p>We may update this policy from time to time. Changes will be posted on this page with a new “Last Updated” date.</p>
                <h4 className="fw-bold">    9. Contact Us</h4>
                <p>For questions about this Privacy Policy, contact us at:</p>
            
                <p >📧 Email<strong><a style={{textDecoration: "none", color: "#fff"}} href="mailto:intowordscards@gmail.com">  intowordscards@gmail.com</a></strong>.</p>
            </div>
            <Footer />
        </div>
    );
}
