import "./card.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Popupcard = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        order_number: "",
    });

    const [formErrors, setFormErrors] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
        order_number: false,
    });

    const handlePhoneInputChange = (e) => {
        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

        if (!value.startsWith("1")) {
            value = "1" + value; // Ensure number starts with '1'
        }

        setFormData({ ...formData, phone: `+${value}` });

        if (value.length < 11) {
            setFormErrors({ ...formErrors, phone: true });
        } else {
            const newErrors = { ...formErrors };
            delete newErrors.phone;
            setFormErrors(newErrors);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = {};
        Object.keys(formData).forEach((key) => {
            if (formData[key].trim() === "") {
                errors[key] = true;
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors({ ...errors });
            toast.error("Please fill out all fields");
            return;
        }

        try {
            const response = await fetch("https://intowordsgc.com/api/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                toast.success("Thanks for contacting us!");
                setFormData({
                    name: "",
                    order_number: "",
                    email: "",
                    phone: "",
                    message: "",
                });
                setFormErrors({
                    name: false,
                    order_number: false,
                    email: false,
                    phone: false,
                    message: false,
                });
            } else {
                toast.error("Failed to send email");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to send email");
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        if (formErrors[e.target.name]) {
            setFormErrors({
                ...formErrors,
                [e.target.name]: false,
            });
        }
    };

    return (
        <div>
            <Helmet>
                <title>Into Words - Contact</title>
            </Helmet>
            <div className="khaplu_modal" style={{ display: "block" }}></div>
            <div className="main_khaplu_popup" style={{ top: "50%", opacity: "1" }}>
                <div className="khaplu_popup">
                    <button className="khaplu_secondary_btn" onClick={onClose}>
                        <img src="cancel.png" alt="Close" />
                    </button>
                    <div className="khaplu_header"></div>
                    <div className="khaplu_body">
                        <form id="contact-form" onSubmit={handleSubmit}>
                            <div className="khaplu_group">
                                <div className="khaplu_field">
                                    <input
                                        type="text"
                                        name="name"
                                        className={formErrors.name ? "error-input" : ""}
                                        placeholder="Enter Your Name*"
                                        value={formData.name}
                                        onChange={handleInputChange} required
                                    />
                                   
                                </div>
                                <div className="khaplu_field">
                                    <input
                                        type="text"
                                        name="order_number"
                                        className={formErrors.order_number ? "error-input" : ""}
                                        placeholder="Enter Order Number*"
                                        value={formData.order_number}
                                        onChange={handleInputChange} required
                                    />
                                  
                                </div>
                            </div>
                            <div className="khaplu_field">
                                <input
                                    type="email"
                                    name="email"
                                    className={formErrors.email ? "error-input" : ""}
                                    placeholder="Enter Email Address*"
                                    value={formData.email} required
                                    onChange={handleInputChange}
                                />
                               
                            </div>
                            <div className="khaplu_field">
                                <input
                                    type="text"
                                    name="phone"
                                    className={formErrors.phone ? "error-input" : ""}
                                    placeholder="Enter Phone Number*"
                                    value={formData.phone}
                                    onChange={handlePhoneInputChange} // Use the updated phone validation
                                />
                                {formErrors.phone && <p className="error-message">Invalid phone number</p>}
                            </div>
                            <div className="khaplu_field">
                                <textarea
                                    name="message"
                                    className={formErrors.message ? "error-input" : ""}
                                    placeholder="Write a message*"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                ></textarea>
                                {formErrors.message && <p className="error-message">Please enter your message</p>}
                            </div>
                            <div className="khaplu_field">
                                <input type="submit" className="khaplu_submit" value="Submit Now" />
                            </div>
                        </form>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};

export default Popupcard;
