import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header/Header';
import { Footer } from './components/Footer';

const stripePromise = loadStripe('pk_live_51PLtOnRuBltPie1wpZ1aJgrCRtbd5Te6glYw1miXuuJGWZz9dgjFkKtE1ta09K194S2lq8uElYDTwhDdOTxp2OZB00y6BEaYiD'); // Replace with your Stripe publishable key.

const Physicalpayment = () => {
    const location = useLocation();
    const { card } = location.state || {};

    if (!card) {
        return <div>No card information provided.</div>;
    }

    return (
        <>
            <div className='' style={{ backgroundColor: "#000", height: "auto" }}
            >
                <Header />
                <div className="payment-page">
                    <Helmet>
                        <title>Into Words - Payment</title>
                    </Helmet>
                    <div className="product-info">
                        <img src={`data:image/png;base64,${card.image}`} className="h-75 w-50 mx-auto d-flex" alt={card.card_name} />
                        <h2 className='text-center'>{card.card_name}</h2>
                        <p className='text-center'>{card.para}</p>
                        <h3 className='text-center'>{`${card.price} x ${card.quantity} = $${(parseFloat(card.price.slice(1)) * card.quantity).toFixed(2)}`}</h3>
                    </div>
                    <div className="payment-form">
                        <h2>Pay with Card</h2>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm card={card} />
                        </Elements>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

const CheckoutForm = ({ card }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        senderName: '',
        phoneNumber: '',
        senderEmail: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        quantity: card.quantity || 1,

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCardDetails, setShowCardDetails] = useState(false); // New state

    const handlePhoneInputChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        if (!value.startsWith('1')) {
            value = '1' + value; // Ensure it starts with 1
        }
        setFormData({ ...formData, phoneNumber: `+${value}` });

        if (value.length < 11) {
            setErrors({ ...errors, phoneNumber: 'Invalid phone number' });
        } else {
            const newErrors = { ...errors };
            delete newErrors.phoneNumber;
            setErrors(newErrors);
        }
    };
    const handleInputChanges = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: name === 'quantity' ? parseInt(value, 10) || 1 : value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };


    const handleCheckout = () => {
        console.log('Checkout button clicked');
        const validationErrors = {};

        // Validate only first-step fields
        const requiredFields = [
            'senderName',
            'phoneNumber',
            'senderEmail',
            'address1',
            'city',
            'state',
            'zip',
            'quantity',
        ];

        requiredFields.forEach((field) => {
            if (!formData[field] || (typeof formData[field] === 'string' && !formData[field].trim())) {
                validationErrors[field] = `${field.replace(/([A-Z])/g, ' $1')} is required`;
            }
        });

        if (!/^\S+@\S+\.\S+$/.test(formData.senderEmail)) {
            validationErrors.senderEmail = 'Invalid email format';
        }

        if (formData.quantity <= 0 || !Number.isInteger(formData.quantity)) {
            validationErrors.quantity = 'Quantity must be a valid positive number';
        }

        if (Object.keys(validationErrors).length > 0) {
            console.log('Validation Errors:', validationErrors);
            setErrors(validationErrors);
            return;
        }

        setErrors({});
        setShowCardDetails(true); // Show card details section
    };

    const handlePayment = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            toast.error('Stripe is not loaded yet.');
            return;
        }

        setIsSubmitting(true);

        try {
            const cardElement = elements.getElement(CardElement);
            const { token, error } = await stripe.createToken(cardElement);

            if (error) {
                setIsSubmitting(false);
                toast.error(error.message);
                return;
            }

            const payload = {
                sender_name: formData.senderName,
                phone_number: formData.phoneNumber,
                sender_email: formData.senderEmail,
                address1: formData.address1,
                city: formData.city,
                state: formData.state,
                zip: formData.zip,
                quantity: formData.quantity,
                card_id: card.id,
                price: parseFloat(card.price.slice(1)) * formData.quantity,
                stripeToken: token.id,
            };

            const response = await axios.post(
                'https://intowordsgc.com/api/physical-card/order',
                payload,
                { headers: { 'Content-Type': 'application/json' } }
            );

            // Extract order number from backend response
            const order_id = response.data.order_id;

            toast.success('Payment successful!');

            // Navigate to ThankYou page and pass orderNumber as state
            navigate('/thank-you', { state: { order_id } });

        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        } finally {
            setIsSubmitting(false);
        }
    };



    return (
        <>


            <form onSubmit={handlePayment}>
                {!showCardDetails ? (
                    <>
                        {/* {[
                        { name: 'senderName', label: 'Sender Name', type: 'text' },
                        { name: 'receiverName', label: 'Receiver Name', type: 'text' },
                        { name: 'senderEmail', label: 'Receiver Email', type: 'email' },
                        { name: 'receiverPhoneNumber', label: 'Receiver Phone Number', type: 'tel' },
                        { name: 'address1', label: 'Address 1', type: 'text' },
                        { name: 'address2', label: 'Address 2 (Optional)', type: 'text' },
                        { name: 'city', label: 'City', type: 'text' },
                        { name: 'state', label: 'State', type: 'text' },
                        { name: 'zip', label: 'ZIP Code', type: 'text' },
                        { name: 'quantity', label: 'Quantity', type: 'number', min: 1 },
                    ].map(({ name, label, type, min }, index) => (
                        <div class="container ">
                            <div className={`row ${index % 2 === 0 ? 'mt-3' : ''}`} key={name}>

                                <div className="form-group col-lg-6 col-sm-12">
                                    <label>{label}</label>
                                    <input
                                        type={type}
                                        name={name}
                                        min={min || undefined}
                                        className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
                                        value={formData[name]}
                                        onChange={handleInputChanges}
                                    />
                                    {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
                                </div>
                            </div>

                        </div>
                    ))} */}
                        <div className="row">
                            <div className="form-group col-lg-6 col-sm-12">
                                <label className="form-label">Name</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.senderName ? 'is-invalid' : ''}`}
                                    name="senderName"
                                    value={formData.senderName}
                                    onChange={handleInputChanges}
                                    required
                                />
                                {errors.senderName && <div className="invalid-feedback">{errors.senderName}</div>}
                            </div>
                            <div className="form-group col-lg-6 col-sm-12 ">
                                <label htmlFor="inputEmail" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className={`form-control ${errors.senderEmail ? 'is-invalid' : ''}`}
                                    id="inputEmail"
                                    name="senderEmail"
                                    value={formData.senderEmail}
                                    onChange={handleInputChanges}
                                    required
                                />
                                {errors.senderEmail && <div className="invalid-feedback">{errors.senderEmail}</div>}
                            </div>
                            <div className="form-group col-lg-6 col-sm-12">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="tel"
                                    className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handlePhoneInputChange}
                                    required
                                />
                                {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                            </div>


                            <div className="col-lg-6 col-sm-12 mt-2">
                                <label htmlFor="inputAddress" className="form-label">Address Line 1</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                    id="inputAddress"
                                    name="address1"
                                    value={formData.address1}
                                    onChange={handleInputChanges}
                                    placeholder="1234 Main St"
                                    required
                                />
                                {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                            </div>

                            <div className="col-lg-4 col-sm-12 mt-2">
                                <label htmlFor="inputCity" className="form-label">City</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.city ? 'is-invalid' : ''}`}

                                    name="city"
                                    value={formData.city}
                                    onChange={handleInputChanges}
                                    required
                                />
                                {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                            </div>
                            <div className="col-lg-4 col-sm-12 mt-2">
                                <label htmlFor="inputState" className="form-label">State</label>
                                <select
                                    id="inputState"
                                    className={`form-select form-control ${errors.state ? 'is-invalid' : ''}`}
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChanges}
                                    required
                                >
                                    <option value="">Choose...</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                                {errors.state && <div className="invalid-feedback">{errors.state}</div>}
                            </div>
                            <div className="col-lg-2 col-sm-12 mt-2">
                                <label htmlFor="inputZip" className="form-label">Zip Code</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.zip ? 'is-invalid' : ''}`}

                                    name="zip"
                                    value={formData.zip}
                                    onChange={handleInputChanges}
                                    required
                                />
                                {errors.zip && <div className="invalid-feedback">{errors.zip}</div>}
                            </div>
                            <div className="col-lg-2 col-sm-12 mt-2">
                                <label htmlFor="quantity" className="form-label" >Quantity</label>
                                <input
                                    type="tel"
                                    className={`form-control ${errors.zip ? 'is-invalid' : ''}`}

                                    name="quantity"
                                    value={formData.quantity}
                                    readOnly
                                    required
                                />
                                {errors.zip && <div className="invalid-feedback">{errors.zip}</div>}
                            </div>
                        </div> <br />
                        <button type="button" className="btn btn-primary mt-3" onClick={handleCheckout}>
                            Checkout
                        </button>

                    </>
                ) : (
                    <>
                        <div className="form-group">
                            <label>Cardholder Name</label>
                            <input
                                type="text"
                                name="cardholderName"
                                className={`form-control ${errors.cardholderName ? 'is-invalid' : ''}`}
                                value={formData.cardholderName || ''}
                                onChange={handleInputChanges}
                            />
                            {errors.cardholderName && <div className="invalid-feedback">{errors.cardholderName}</div>}
                        </div>
                        <div className="form-group">
                            <label>Card Details</label>
                            <CardElement className="form-control" />
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting || !stripe}>
                            {isSubmitting ? 'Processing...' : 'Pay Now'}
                        </button>
                    </>
                )}

                <ToastContainer />
            </form>
            {/* <Footer /> */}
        </>
    );
};

export default Physicalpayment;
