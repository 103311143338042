import { Footer } from "./Footer";
import Header from "./Header/Header";

export default function RefundPolicy() {
    return (
        <div className='bg-dark text-light' style={{ backgroundColor: "#111111", height: "auto" }}>
            <Header />
            <div className="container mt-5">
                <h2 className="fw-bold">Refund Policy</h2>
                <p><strong>Last Updated:</strong> Feb 5th, 2025</p>

                <p>At Into Words Greeting Cards, we strive to provide high-quality greeting cards. Please review our refund policy before making a purchase.</p>

                <h4 className="fw-bold">1. Digital Cards (Non-Refundable)</h4>
                <ul>
                    <li>Due to the nature of digital downloads, all digital card purchases are final and non-refundable.</li>
                    <li>If you do not receive your digital card within 10 minutes, contact us for assistance.</li>
                </ul>

                <h4 className="fw-bold">2. Physical Cards (Refund & Replacement Policy)</h4>
                <p>We accept refunds or replacements for physical cards under the following conditions:</p>
                <ul>
                    <li><strong>Damaged or Defective Items:</strong> If your card arrives damaged, send us a photo within 7 days of delivery for a replacement or refund.</li>
                    <li><strong>Incorrect Order:</strong> If you receive the wrong card, we will send the correct one at no additional cost.</li>
                    <li><strong>Lost Shipments:</strong> If your order does not arrive within the expected timeframe, please contact us. We may offer a replacement or refund after verifying with the carrier.</li>
                </ul>

                <h4 className="fw-bold">3. Refund Process</h4>
                <ul>
                    <li>To request a refund or replacement, email <strong><a style={{textDecoration: "none", color: "#fff"}} href="mailto:intowordscards@gmail.com">  intowordscards@gmail.com</a></strong> with your order number and issue details.</li>
                    <li>Refunds are processed to the original payment method within 5-10 business days after approval.</li>
                    <li>We do not cover return shipping costs unless the error was on our end.</li>
                </ul>

                <h4 className="fw-bold">4. Exceptions (Non-Refundable Items)</h4>
                <ul>
                    <li>Digital cards (as they are instantly delivered).</li>
                    <li>Physical cards with minor imperfections that do not affect usability.</li>
                    <li>Orders where incorrect shipping details were provided by the customer.</li>
                </ul>

                <p>For refund requests, contact us at <strong><a style={{textDecoration: "none", color: "#fff"}} href="mailto:intowordscards@gmail.com">  intowordscards@gmail.com</a></strong>.</p>
            </div>
            <Footer />
        </div>
    );
}
