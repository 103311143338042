import { Footer } from "./Footer";
import Header from "./Header/Header";


export default function TermsAndConditions() {
    return (
        <div className='bg-dark text-light ' style={{ backgroundColor: "#111111", height: "auto" }}>

            <Header />
            <div className="container mt-5">
                <h1>Terms and Conditions</h1>
                <p><strong>Last Updated:</strong> Feb 5<sup>th</sup>, 2025</p>

                <p>Welcome to <strong>Into Words Greeting Cards</strong>. By accessing and purchasing from our website, you agree to the following terms and conditions.</p>

                <hr />

                <h2>1. Products & Services</h2>
                <p>We offer two types of greeting cards:</p>
                <ul>
                    <li><strong>Digital Cards:</strong> Delivered instantly via email after payment.</li>
                    <li><strong>Physical Cards:</strong> Shipped to the address provided at checkout.</li>
                </ul>

                <h2>2. Orders & Payment</h2>
                <ul>
                    <li>All orders must be placed through our website.</li>
                    <li>Payments are securely processed through Stripe. We accept major credit/debit cards.</li>
                    <li>We reserve the right to cancel orders for any reason, including suspected fraud.</li>
                </ul>
                <h2>3. Shipping & Delivery</h2>
                <ul>
                    <li><strong>Digital Cards:</strong> Emailed within minutes of purchase. Please check your spam/junk folder.
                    </li>
                    <li><strong>Physical Cards: </strong>Shipping times and costs vary based on your location and selected shipping method. We are not responsible for carrier delays</li>

                </ul>
                <h2>4. Refunds & Cancellations</h2>
                <ul>
                    <li>Due to the nature of digital products, all sales of <strong>digital cards are final </strong>  and non-refundable.
                        .</li>
                    <li><strong>Physical cards </strong> may be eligible for refunds or replacements under specific conditions (see Refund Policy).
                    </li>
                    <li>If you do not receive your digital card within 10 minutes, please contact us.
                    </li>
                </ul>
                <h2>5. Intellectual Property</h2>

                <p>All designs, images, and content on our website are owned by <strong>Into Words Greeting Cards. </strong> You may not copy, reproduce, or distribute our products without permission.
                </p>
                <h2>6. Limitation of Liability</h2>

<p>We are not responsible for lost or undelivered emails due to incorrect addresses, spam filtering, or technical issues. Physical shipments are subject to carrier delays and errors beyond our control.

</p>


                <h2>7. Changes to These Terms</h2>
                <p>We may update these Terms and Conditions at any time. Continued use of our website implies acceptance of any changes.</p>

                <p>For questions, contact us at <a style={{textDecoration: "none", color: "#fff"}} href="mailto:intowordscards@gmail.com">  intowordscards@gmail.com</a>.</p>
            </div>
            <Footer />
        </div>
    );
}